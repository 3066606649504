<div class="main-banner">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="main-banner-content" *ngFor="let Content of mainBannerContent;">
                    <div class="col-md-12 text-center">
                        <img class="logo" src="assets/img/CreatechAcademyLogo1.png">
                        <h1 class="wow animate__animated animate__fadeInLeft text-center" data-wow-delay="00ms" data-wow-duration="1000ms">Scratch Beginners</h1>
                    </div>
                    <h2 class="wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">Start Date: Wednesday - October 16th</h2>
                    <h4 class="wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">Where: 2000 Falcon Trace Blvd, Orlando, FL 32837</h4>
                    
                    <p class="wow animate__animated animate__fadeInLeft" data-wow-delay="100ms" data-wow-duration="1000ms">
                        Our Scratch Beginner course is designed for kids to learn the basics of coding in a fun and interactive way. Students will explore how computers work, develop critical thinking, and create their own games using Scratch, a visual programming language.
                    </p>
                    <br/>
                    <h3 class=""><b class="main-color-text">20%</b> Discount for the first 3 students to register.</h3>
                    <p>
                        Discount will be applied to the last month payment and will include the 20% discount for the 3 months.
                    </p>
                    <div class="btn-box">
                        <button (click)="openRegistration()" class="default-btn wow animate__animated animate__fadeInRight" data-wow-delay="200ms" data-wow-duration="1000ms"><i class="flaticon-rocket"></i>Register<span></span></button>
                        <a href="{{Content.videoBtnLink}}" class="video-btn wow animate__animated animate__fadeInLeft popup-youtube" data-wow-delay="300ms" data-wow-duration="1000ms"><i class="flaticon-google-play"></i> {{Content.videoBtnText}}</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="main-banner-animation-image">
                    <img src="assets/img/Scratch-Emblem.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="200ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true">
                </div>
            </div>
        </div>
    </div>
</div>

<section class="courses-details-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="courses-details-desc">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true">Overview</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="curriculum-tab" data-bs-toggle="tab" data-bs-target="#curriculum" type="button" role="tab" aria-controls="curriculum" aria-selected="false">Curriculum</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="instructor-tab" data-bs-toggle="tab" data-bs-target="#instructor" type="button" role="tab" aria-controls="instructor" aria-selected="false">Instructor</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#reviews" type="button" role="tab" aria-controls="reviews" aria-selected="false">FAQ</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="overview" role="tabpanel">
                            <div class="courses-overview">
                                <h3>Course Description</h3>
                                <p>
                                    Our Scratch Beginner course introduces kids to the world of coding through Scratch, a visual programming language developed by MIT. Perfect for young learners, this course teaches foundational coding concepts in a fun and engaging way.
                                </p>
                                <img src="assets/img/ScratchExample.jpeg">
                                <br/><br/>
                                <p>
                                    Students will start by learning how computers work and then dive into coding basics, including how to control movement using X and Y coordinates. Throughout the course, they will explore key coding elements such as:
                                </p>
                                <div class="about-text">
                                    <ul class="features-list">
                                        <li><i class="flaticon-tick"></i> <b>Actions:</b> Understanding how to make characters move and perform tasks.</li>
                                        <li><i class="flaticon-tick"></i> <b>Loops:</b> Learning how to repeat actions efficiently.</li>
                                        <li><i class="flaticon-tick"></i> <b>Events:</b> Triggering actions in response to specific conditions.</li>
                                        <li><i class="flaticon-tick"></i> <b>Variables:</b> Storing and using data.</li>
                                        <li><i class="flaticon-tick"></i> <b>Conditionals:</b> Making decisions within the program based on different scenarios.</li>
                                        <li><i class="flaticon-tick"></i> <b>Messages and Inputs:</b> Communicating between different parts of the code.</li>
                                    </ul>
                                </div>
                                <br/>
                                <p>
                                    By the end of the course, students will use their new skills to create a fun, interactive game called “Catch the Fruit,” where they apply everything they've learned, from event triggers to conditionals. This course not only teaches coding but also enhances problem-solving, creativity, and logical thinking.
                                </p>

                                <h3>Who this course is for</h3>
                                <p>This course is perfect for kids aged <b>8</b> to <b>14</b> who are new to coding and want to learn the basics in a fun, creative environment. No prior coding experience is needed, just a curious mind and a love for technology!</p>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="curriculum" role="tabpanel">
                            <div class="courses-curriculum">
                                <h3>1.- Coding Introduction</h3>
                                <p>
                                    In this module, students will get an overview of what coding is and how it powers the technology around us. They'll explore how computers think, process information, and execute commands. This introduction lays the foundation for understanding programming logic and structure.
                                </p>

                                <h3>2.- Getting Started with Scratch</h3>
                                <p>
                                    Students will dive into Scratch, a block-based coding platform. They'll learn how to navigate the interface and understand key concepts like sprites and backdrops. This module also introduces the use of the X and Y coordinate system to control sprite movement, making abstract coding concepts easier to grasp.
                                </p>

                                <h3>3.- Actions and Movement</h3>
                                <p>
                                    In this module, students will begin coding simple actions. They'll learn how to move sprites across the screen and animate them with basic commands. By mastering these building blocks, they'll gain the skills to create interactive animations and basic games.
                                </p>

                                <h3>4.- Loops</h3>
                                <p>
                                    Students will discover how to use loops to repeat actions efficiently, saving time and reducing repetitive code. This fundamental concept will allow them to create dynamic programs that run more smoothly.
                                </p>

                                <h3>5.- Events</h3>
                                <p>
                                    This module introduces event-based programming, teaching students how to trigger actions when certain events occur, like when a key is pressed or a sprite is clicked. They'll create more interactive and responsive projects by using event handling.
                                </p>

                                <h3>6.- Variables</h3>
                                <p>
                                    Students will explore the concept of variables, learning how to store and manipulate data. Whether it's keeping score in a game or tracking a player’s position, variables are crucial for creating more complex projects.
                                </p>

                                <h3>7.- Conditionals</h3>
                                <p>
                                    In this module, students will learn how to make their code “think” using conditionals. They'll write programs that can make decisions, such as checking if a condition is met before an action takes place, allowing for more complex interactions in their projects.
                                </p>

                                <h3>8.- Messages and Broadcasting</h3>
                                <p>
                                    Students will dive into inter-sprite communication through broadcasting and receiving messages. They'll learn how different parts of their program can work together, synchronizing actions and adding depth to their projects.
                                </p>

                                <h3>9.- Inputs</h3>
                                <p>
                                    This module focuses on user interaction, teaching students how to incorporate inputs like keyboard or mouse commands to control sprites. By allowing the user to guide the program, students will add an extra level of interactivity to their games.
                                </p>

                                <h3>10.- Final Project – Catch the Fruit</h3>
                                <p>
                                    In the final module, students will apply everything they've learned to create their own game, "Catch the Fruit." They'll bring together all the coding concepts—loops, conditionals, events, and variables—to build a fully functional and interactive game from scratch.
                                </p>

                            </div>
                        </div>
                        <div class="tab-pane fade" id="instructor" role="tabpanel">
                            <div class="courses-instructor">
                                <div class="single-advisor-box">
                                    <div class="row align-items-center">
                                        <div class="col-lg-4 col-md-4">
                                            <div class="advisor-image">
                                                <img src="assets/img/instructor.jpg" alt="image">
                                            </div>
                                        </div>
            
                                        <div class="col-lg-8 col-md-8">
                                            <div class="advisor-content">
                                                <h3>Jesus Mendoza</h3>
                                                <span class="sub-title">Software Engineer (+10 years)</span>
                                                <ul class="social-link">
                                                    <li><a href="https://www.linkedin.com/in/jesus-mendoza-b8768127/" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="reviews" role="tabpanel">
                            <div class="courses-review-comments">
                                
                                <div class="user-review">
                                    <span class="d-block sub-comment">1.- Why is it important for kids to learn to code?</span>
                                    <p>
                                        Learning to code offers numerous benefits for children. It helps develop problem-solving skills, logical thinking, and creativity. Coding encourages kids to think critically and work through challenges systematically. Additionally, it fosters perseverance and resilience as they debug and refine their projects. Beyond these skills, coding also provides a strong foundation for future technology-related careers and equips kids with the tools to understand and shape the digital world around them.          
                                    </p>
                                </div>

                                <div class="user-review">
                                    <span class="d-block sub-comment">2.- Do students need prior coding experience to join this course?</span>
                                    <p>
                                        No prior coding experience is needed! This course is designed for beginners, and we’ll start with the basics, making it easy for kids to follow along and learn at their own pace.            
                                    </p>
                                </div>
                                <div class="user-review">
                                    <span class="d-block sub-comment">3.- What age group is this course suitable for?</span>
                                    <p>
                                        This course is ideal for children aged <b>8</b> to <b>14</b>. It’s perfect for kids who are new to coding and interested in learning how to create their own games and animations.
                                    </p>
                                </div>
                                <div class="user-review">
                                    <span class="d-block sub-comment">4.- How long is the Scratch Beginner course?</span>
                                    <p>
                                        The course consists of 10 modules, with each module lasting approximately 1 hours, except for final project that will last a couple of days. Students can complete the course in 12 weeks.
                                    </p>
                                </div>
                                <div class="user-review">
                                    <span class="d-block sub-comment">5.- What will students create by the end of the course?</span>
                                    <p>
                                        By the end of the course, students will have created their own game, “Catch the Fruit.” This project will showcase their understanding of loops, variables, conditionals, and more.
                                    </p>
                                </div>
                                <div class="user-review">
                                    <span class="d-block sub-comment">6.- Do students need any special software or equipment?</span>
                                    <p>
                                        No, students don’t need to bring any equipment. Computers will be provided, and all coding will be done using Scratch, a free, web-based platform.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="courses-details-info">
                    <ul class="info">
                        <li class="price">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-price-tag"></i> Price</span>
                                $149/month
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-teacher"></i> Instructor</span>
                                Jesus Mendoza
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-stopwatch"></i> Duration</span>
                                12 weeks / 3 months
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-calendar"></i> Schedule</span>
                                Wednesdays <br/>
                                5:15 pm - 6:30 pm
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-web"></i> Capacity</span>
                                12 students
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-team"></i> Ages</span>
                                8 - 14 years old
                            </div>
                        </li>
                    </ul>

                    <div class="btn-box">
                        <button (click)="openRegistration()" class="default-btn"><i class="flaticon-rocket"></i>Register<span></span></button>
                    </div>
                    <!--
                    <div class="courses-share">
                        <div class="share-info">
                            <span>Share This Course <i class="flaticon-share"></i></span>

                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                    -->
                </div>
            </div>
        </div>
    </div>
</section>